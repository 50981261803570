import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Box,
  Container,
  CardActions,
  Divider,
} from "@material-ui/core";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import LinkTwoTone from "@material-ui/icons/LinkTwoTone";
import TimelineTwoToneIcon from "@material-ui/icons/TimelineTwoTone";
import ReadMoreIcon from "@material-ui/icons/InfoTwoTone";
import RefreshIcon from "@material-ui/icons/Refresh";
import { purple, green, grey, indigo, red } from "@material-ui/core/colors";

function SlimsDomainsUI() {
  document.body.style.backgroundColor = grey[300];
  const [loaded, setloaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const history = useHistory();
  const [intervalId, setIntervalId] = useState(null);
  const [disablePurgeButton, setDisablePurgeButton] = useState({
    isDisabled: false,
    text: "Purge Active Domains",
    backgroundColor: null,
  });

  const fetchActiveNextDomains = useCallback(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/slims/domains/activeNextDomains",
        { withCredentials: true }
      );
      setDomains(response.data);
      setloaded(true);
     setDisablePurgeButton({
        isDisabled: response.data.length === 0 ? true : false,
        text: response.data.length === 0 ? "No Active Domains To Purge" : "Purge Active Domains",
        backgroundColor: response.data.length === 0 ? red[300] : null,
      });
    } catch (err) {
      if (err.response?.status === 401) {
        history.push("/");
      } else {
        setError(true);
        setloaded(true);
        setMessage("Failed to fetch domains.");
      }
    }
  }, [history]);

  const purgeActiveDomains = useCallback(async () => {
    setLoading(true);
    clearInterval(intervalId);
    try {

      //set purge: true in activeDomain
      const domainsCopy = domains.map((domain) => {
        if (domain.activeDomain && domain.activeDomain.domain && domain.activeDomain._id) {
          return { ...domain, activeDomain: { ...domain.activeDomain, purge: true, count: "Purging..." } };
        }
        return domain;
      });

      setDomains(domainsCopy);
      setDisablePurgeButton({
        isDisabled: true,
        text: "Purging Active Domains...",
        backgroundColor: red[300],
      });

      


      const payload = {
        toPurge: domains
          .map((domain) => {
            if (domain.activeDomain && domain.activeDomain.domain && domain.activeDomain._id) {
              return { domain: domain.activeDomain.domain, _id: domain.activeDomain._id };
            }
            return null; // Handle missing activeDomain
          })
          .filter(item => item !== null), // Filter out invalid entries
      
        toActivate: domains
          .map((domain) => {
            if (domain.nextDomain && domain.nextDomain.domain && domain.nextDomain._id) {
              return { domain: domain.nextDomain.domain, _id: domain.nextDomain._id };
            }
            return null; // Handle missing nextDomain
          })
          .filter(item => item !== null) // Filter out invalid entries
      };

      const response = await axios.put(
        process.env.REACT_APP_API_URL + "/slims/domains/purgeActiveDomains",
        { ...payload },
        { withCredentials: true }
      );
      setMessage(response.data.message);
      setError(false);
      setDisablePurgeButton({
        isDisabled: false,
        text: "Purge Active Domains",
        backgroundColor: null,
      });
    } catch (err) {
      if (err.response?.status === 401) {
        history.push("/");
      } else {
        setError(true);
        setMessage(err.response?.data?.message || "Purge failed.");
        setDisablePurgeButton({
          isDisabled: false,
          text: "Purge Active Domains",
          backgroundColor: null,
        });
      }
    } finally {
      fetchActiveNextDomains();
      setLoading(false);
      const newIntervalId = setInterval(fetchActiveNextDomains, 6000);
      setIntervalId(newIntervalId);
      setDisablePurgeButton({
          isDisabled: false,
          text: "Purge Active Domains",
          backgroundColor: null,
      });
    }
  }, [fetchActiveNextDomains, history, intervalId, domains]);

  useEffect(() => {
    fetchActiveNextDomains();
    const newIntervalId = setInterval(fetchActiveNextDomains, 6000);
    setIntervalId(newIntervalId);
    return () => clearInterval(newIntervalId);
  }, [fetchActiveNextDomains]);

  const cardStyle = {
    margin: "20px auto",
    width: "65%",
  };

  const responsiveCardStyle = {
    margin: "20px auto",
    width: "auto",
  };

  if (loaded) {
    return (
      <Container
        style={{
          padding: 20,
          position: "relative",
          overflow: "auto",
          maxHeight: "80vh",
        }}
      >
        <Snackbar
          open={!!message}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={4000}
          onClose={() => setMessage("")}
        >
          <Alert
            onClose={() => setMessage("")}
            severity={error ? "error" : "success"}
          >
            {message}
          </Alert>
        </Snackbar>

        <Box style={{ width: "100%", textAlign: "left" }}>
          <Typography variant="h4" style={{ marginBottom: 5 }}>
            Slims Domains Manager
          </Typography>
          <Box
            style={{ display: "flex", alignItems: "center", marginBottom: 5 }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={purgeActiveDomains}
              disabled={disablePurgeButton.isDisabled && true}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <RefreshIcon />
                )
              }
              style={{ marginTop: 1, marginRight: 10,
                backgroundColor: disablePurgeButton.backgroundColor,
                color: "#fff"
              
              }}
            >
              {disablePurgeButton.text}
            </Button>
            <Typography variant="h6">
              In Purge:{" "}
              {domains.filter((domain) => domain.activeDomain.purge).length}
            </Typography>
          </Box>
          <Divider style={{ margin: "10px 0" }} />
        </Box>

        {/* {loading && <CircularProgress style={{ margin: 20 }} />} */}

        <Grid container spacing={4} style={{ marginTop: 20 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="center" style={{ marginTop: 30 }}>
              Active Domains
            </Typography>
            <Box
              style={{ maxHeight: "50vh", padding: "10px", overflowY: "auto" }}
            >
              {domains.length > 0 ? (
                domains.map((domain, index) => (
                  <Card
                    key={index}
                    style={
                      window.innerWidth < 600 ? responsiveCardStyle : cardStyle
                    }
                    elevation={20}
                  >
                    <CardHeader
                      style={{ backgroundColor: domain.activeDomain?._id ? indigo[400] : red[300]  }}
                      title={domain.activeDomain._id || "No Active Domain"}
                      titleTypographyProps={{
                        variant: "subtitle1",
                        style: { color: "white" },
                      }}
                      avatar={
                        <Avatar style={{ backgroundColor: purple[400] }}>
                          AD
                        </Avatar>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <Typography
                        align="center"
                        variant="h6"
                        title="Active Domain"
                      >
                        <LinkTwoTone fontSize="large" color="primary" />{" "}
                        {domain.activeDomain.domain || "No Active Domain"}
                      </Typography>
                      <Divider />
                      <Typography
                        align="center"
                        variant="h6"
                        title="Total Count"
                      >
                        <TimelineTwoToneIcon fontSize="large" color="primary" />{" "}
                        {domain.activeDomain.count || "0"}
                      </Typography>
                    </CardContent>
                    <CardActions
                      style={{
                        backgroundColor: domain.activeDomain.purge || !domain.activeDomain.domain
                          ? red[300]
                          : green[300],
                      }}
                    >
                      <Button
                        startIcon={<ReadMoreIcon />}
                        fullWidth
                        variant="contained"
                        style={{
                          backgroundColor: domain.activeDomain.purge || !domain.activeDomain.domain
                            ? red[300]
                            : green[300],
                        }}
                      >
                       {
                        !domain.activeDomain.domain ? "No Active Domain" 
                        : 
                        domain.activeDomain.purge ? "In Purge" : "Active Domain"
                       }
                      </Button>
                    </CardActions>
                  </Card>
                ))
              ) : (
                <Typography>No Active Domains Found.</Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="center" style={{ marginTop: 30 }}>
              Next Domains
            </Typography>
            <Box
              style={{ maxHeight: "50vh", padding: "10px", overflowY: "auto" }}
            >
              {domains.length > 0 ? (
                domains.map((domain, index) => (
                  <Card
                    key={index}
                    style={
                      window.innerWidth < 600 ? responsiveCardStyle : cardStyle
                    }
                    elevation={20}
                  >
                    <CardHeader
                      style={{ backgroundColor: domain.nextDomain?._id ? indigo[400] : red[300] }}
                      title={domain.nextDomain._id || "No Next Domain Available"}
                      titleTypographyProps={{
                        variant: "subtitle1",
                        style: { color: "white" },
                      }}
                      avatar={
                        <Avatar style={{ backgroundColor: purple[400] }}>
                          ND
                        </Avatar>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <Typography
                        align="center"
                        variant="h6"
                        title="Next Domain"
                      >
                        <LinkTwoTone fontSize="large" color="primary" />{" "}
                        {domain.nextDomain?.domain || "No Next Domain Available"}
                      </Typography>
                      <Divider />
                      <Typography
                        align="center"
                        title="Total Count"
                        variant="h6"
                      >
                        <TimelineTwoToneIcon fontSize="large" color="primary" />{" "}
                        {domain.nextDomain?.count || "0"}
                      </Typography>
                    </CardContent>
                    <CardActions style={{ backgroundColor: domain.nextDomain._id ? green[300] : red[300] }}>
                      <Button
                        startIcon={<ReadMoreIcon />}
                        fullWidth
                        variant="contained"
                        style={{ backgroundColor:  domain.nextDomain._id ? green[300] : red[300] }}
                      >
                        {
                          domain.nextDomain._id ? "Next In Queue" : "No Domain To Queue"
                        }
                      </Button>
                    </CardActions>
                  </Card>
                ))
              ) : (
                <Typography>No Next Domains Found.</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return <h1>Please Wait....</h1>;
  }
}

export default SlimsDomainsUI;
